import React from 'react';
import { Divider, Card, Grid, Typography, CircularProgress, Box, Link } from '@mui/material';


const CourseReviewIndicator = ({ course, users, preparing }) => {

    const filterUsers = (userList, type, status) => {
        const filteredUsers = [];
        for (let user of userList) {
            for (let [key, value] of Object.entries(user.enrollments)) {
                if (key.slice(0, 3) === course.id && key.slice(3, 5) === "__" && key[12] === type) {
                    if (status === "enrolled" || value[status] === true) {
                        filteredUsers.push(user);
                    }
                }
            }
        }
        return filteredUsers.length;
    }

    return (
        <Card sx={{ "margin": "10px 10px", "minWidth": "500px", "width": "500px"}}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ "height": "30px", "backgroundColor": "#d23831", "paddingTop": "10px" }}>
                <Typography variant={'p'} color="white" fontWeight={"bold"} gutterBottom width={"100%"} textAlign={"center"} fontSize={"0.8em"}>
                    Información general
                </Typography>
            </Grid>
            <Box fontSize={"0.8em"}>
                {preparing ?
                    <Box textAlign={"center"}>
                        <CircularProgress sx={{ "color": "#d23831" }} />
                    </Box>
                    :
                    <>
                        <Grid container display={"flex"} p={"0 20px"} justifyContent={"space-between"} mb={"10px"}>
                            <Grid item xs={6} md={5} m={"10px 0"}>
                                <Typography fontWeight={"bold"} variant="p">Plataforma</Typography>
                                <Divider />
                                <Typography variant="p">Sitio</Typography>
                                <Typography variant="p">Curso base (edX)</Typography>
                                <Typography variant="p">Implementación más reciente</Typography>
                            </Grid>
                            <Grid item xs={6} md={7} m={"10px 0"}>
                                <Typography mt={"19px"} variant="p"></Typography>
                                <Divider />
                                <Typography textAlign={"center"} variant="p">
                                    {course.site === "redfid" ? "RedFID" : course.site === "cmmeduformacion" ? "CMMEdu Formación" : "No hay sitio"}
                                </Typography>
                                <Typography textAlign={"center"} variant="a">
                                    <Link 
                                        sx={{"textDecoration": "none"}} 
                                        target={(course.base === "" || (course.site !== "redfid" && course.site !== "cmmeduformacion")) ? "_self" : "_blank"} 
                                        href={course.base === "" ? "#" : course.site === "cmmeduformacion" ? "https://studio.cmmeduformacion.uchile.cl/course/" + course.base : course.site === "redfid" ? "https://studio.cursos.redfid.cl/course/" + course.base : "#"}
                                    >
                                        
                                        {course.base !== "" ? course.base : "No hay curso base"}
                                    </Link>
                                </Typography>
                                <Typography textAlign={"center"} variant="p">
                                    {course.implementations[Object.keys(course.implementations)[Object.keys(course.implementations).length - 1]].semester}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <Typography fontWeight={"bold"} variant="p">E-learning</Typography>
                                <Divider />
                                <Typography variant="p">Implementaciones</Typography>
                                <Typography variant="p">Secciones</Typography>
                                <Typography variant="p">Inscripciones</Typography>
                                <Typography variant="p">Completaron el curso</Typography>
                                <Typography variant="p">Aprobaron el curso</Typography>
                            </Grid>
                            <Grid item xs={6} md={1}>
                                <Typography mt={"19px"} variant="p"></Typography>
                                <Divider />
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {course.implementations.filter(i => i.type === "e-learning").length}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {course.implementations.filter(i => i.type === "e-learning").reduce((acc, curr) => acc + curr.sections.length, 0)}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "E", "enrolled")}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "E", "completed")}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "E", "approved")}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <Typography fontWeight={"bold"} variant="p">B-learning</Typography>
                                <Divider />
                                <Typography variant="p">Implementaciones</Typography>
                                <Typography variant="p">Secciones</Typography>
                                <Typography variant="p">Ubicaciones</Typography>
                                <Typography variant="p">Inscripciones</Typography>
                                <Typography variant="p">Completaron el curso</Typography>
                                <Typography variant="p">Aprobaron el curso</Typography> 
                            </Grid>
                            <Grid item xs={6} md={1}>
                                <Typography mt={"19px"} variant="p"></Typography>
                                <Divider />
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {course.implementations.filter(i => i.type === "b-learning").length}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {course.implementations.filter(i => i.type === "b-learning").reduce((acc, curr) => acc + curr.sections.length, 0)}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {course.implementations.filter(i => i.type === "b-learning").reduce((acc, curr) => acc + curr.sections.map(s => s.location).reduce((acc, curr) => acc.includes(curr) ? acc : [...acc, curr], []).length, 0)}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "B", "enrolled")}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "B", "completed")}
                                </Typography>
                                <Typography textAlign={"center"} mr={"10px"} variant="p">
                                    {filterUsers(users, "B", "approved")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
        </Card>
    );

}

export default CourseReviewIndicator;