import React, { useState, useEffect } from 'react';
import Header from '../../Header/Header';
import Summary from './components/Summary';
import Seguimiento from './components/Seguimiento';
import Encuestas from './components/Encuestas';
import Users from './components/Users';
import Course from './components/Course';
import Diplomado from './components/Diplomado';
import { Box, Typography, Drawer, AppBar, CssBaseline, List, ListItem, ListItemButton, Divider, CircularProgress } from '@mui/material';
import { getCatalog } from '../../requests/getCatalog';
import { getUsers } from '../../requests/getUsers';
import { getEquivalencias } from '../../requests/getEquivalencias';
import { getEncuestasQuestions } from '../../requests/getEncuestasQuestions';

const drawerWidth = 240;

const Admin = ({ keycloak, permissions }) => {

  const [loadingCourses, setLoadingCourses] = useState(true);
  const [catalog, setCatalog] = useState(null);
  const [users, setUsers] = useState(null);
  const [equivalencias, setEquivalencias] = useState(null);
  const [dashboardType, setDashboardType] = useState("other");
  const [encuestasQuestions, setEncuestasQuestions] = useState({});
  const [availableDashboards, setAvailableDashboards] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const [selectedImplementation, setSelectedImplementation] = useState("none");
  const [selectedSection, setSelectedSection] = useState("none");

  const fetchData = async () => {
    try {
      setLoadingCourses(true);
      const response_catalog = await getCatalog(keycloak.token);
      const response_users = await getUsers(keycloak.token);
      const response_equivalencias = await getEquivalencias(keycloak.token);
      const response_encuestas_questions = await getEncuestasQuestions(keycloak.token);
      setCatalog(response_catalog.courses);
      setUsers(response_users.users);
      setEquivalencias(response_equivalencias.equivalencias);
      setEncuestasQuestions(response_encuestas_questions.courseData);
      setLoadingCourses(false);
    } catch (error) {
      setLoadingCourses(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleSectionClick = (section, type) => {
    setSelectedImplementation("none");
    setSelectedSection("none");
    setSelectedDashboard(section);
    setDashboardType(type);
  };

  const mapDashboards = (dashboard) => {
    switch (dashboard) {
      case "resumen":
        return "Resumen";
      case "seguimiento":
        return "Seguimiento";
      case "usuarios":
        return "Usuarios";
      case "encuestas":
        return "Encuestas";
      default:
        return dashboard;
    }
  };

  const mapTopics = (topic) => {
    switch (topic) {
      case "numeros":
        return "Números";
      case "algebra":
        return "Álgebra";
      case "geometria":
        return "Geometría";
      case "datos":
        return "Datos y azar";
      case "otros":
        return "Otros cursos";
      default:
        return topic;
    }
  };

  const filterDashboards = () => {
    let dashboards = [];
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("resumen")) {
      dashboards.push("resumen");
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("seguimiento")) {
      dashboards.push("seguimiento");
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("usuarios")) {
      dashboards.push("usuarios");
    }
    if (permissions.dashboards.includes("*") || permissions.dashboards.includes("encuestas")) {
      dashboards.push("encuestas");
    }
    const addCourseDashboards = (topic) => {
      const topicCourses = Object.entries(catalog).filter(
        ([key, course]) => course.topic === topic &&
          (permissions.dashboards.includes("*") || permissions.dashboards.includes(key))
      );

      if (topicCourses.length > 0) {
        dashboards.push({ header: mapTopics(topic), courses: topicCourses });
      }
    };
    const addDiplomadoDashboards = () => {
      const topicCourses = Object.entries(catalog).filter(
        ([key, course]) => (course.topic === "diplomado_1" || course.topic === "diplomado_2") &&
          (permissions.dashboards.includes("*") || permissions.dashboards.includes(key))
      );

      if (topicCourses.length > 0) {
        dashboards.push({ header: "Diplomados", courses: topicCourses });
      }
    };
    if (catalog) {
      addCourseDashboards("numeros");
      addCourseDashboards("algebra");
      addCourseDashboards("geometria");
      addCourseDashboards("datos");
      addDiplomadoDashboards();
      addCourseDashboards("otros");
    }
    setAvailableDashboards(dashboards);
  };

  useEffect(() => {
    document.title = "Suma y Sigue | Panel de administración";
    fetchData();
  }, []);

  useEffect(() => {
    if (!loadingCourses && catalog) {
      if (!availableDashboards) {
        filterDashboards();
      } else {
        if (permissions.dashboards.includes("*")) {
          setSelectedDashboard("resumen");
        } else if (availableDashboards.length > 0) {
          const firstDashboard = availableDashboards[0];
          if (typeof firstDashboard === "string") {
            setSelectedDashboard(firstDashboard);
          } else if (firstDashboard.courses.length > 0 && firstDashboard.header !== "Diplomados") {
            setSelectedDashboard(firstDashboard.courses[0][0]);
            setDashboardType("course");
          } else if (firstDashboard.courses.length > 0 && firstDashboard.header === "Diplomados") {
            setSelectedDashboard(firstDashboard.courses[0][0]);
            setDashboardType("diplomado");
          }
        } else {
          setSelectedDashboard(null);
        }
      }
    }
  }, [loadingCourses, catalog, availableDashboards, permissions.dashboards]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Header current={"admin"} />
      </AppBar>
      {(catalog && !loadingCourses && availableDashboards) ? (
        availableDashboards.length > 0 ? (
          <>
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}
            >
              <Box sx={{ overflow: 'auto', marginTop: "100px" }}>
                <List>
                  {availableDashboards.map((dashboard) => (
                    typeof dashboard === "string" ? (
                      <ListItem key={dashboard} disablePadding sx={{ "backgroundColor": selectedDashboard === dashboard ? "#60696C" : "white" }}>
                        <ListItemButton onClick={() => handleSectionClick(dashboard, "other")}>
                          <Typography variant="p" sx={{ "textAlign": "center", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedDashboard === dashboard ? "white" : "#646464"}>{mapDashboards(dashboard)}</Typography>
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      <React.Fragment key={dashboard.header}>
                        <Divider />
                        <Typography variant="p" sx={{
                          color: "#d23831",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "1.1em",
                          marginTop: "15px",
                          fontFamily: "Avenir Heavy"
                        }}>{dashboard.header}</Typography>
                        <List>
                          {dashboard.courses.map(([key, course]) => (
                            <ListItem key={key} disablePadding sx={{ "backgroundColor": selectedDashboard === key ? "#60696C" : "white" }}>
                              <ListItemButton onClick={() => handleSectionClick(`${key}`, dashboard.header === "Diplomados" ? "diplomado" : "course")}>
                                <Typography variant="p" sx={{ "textAlign": "left", "width": "100%" }} fontSize={"0.85em"} m={"5px 0"} color={selectedDashboard === key ? "white" : "#646464"}>{course.name}</Typography>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </React.Fragment>
                    )
                  ))}
                </List>
              </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={"100px"}>
              {selectedDashboard === "resumen" ?
                <Summary courses={catalog} users={users}></Summary>
                : selectedDashboard === "seguimiento" ?
                  <Seguimiento keycloak={keycloak} permissions={permissions} catalog={catalog} equivalencias={equivalencias}></Seguimiento>
                  : selectedDashboard === "usuarios" ? <Users keycloak={keycloak} catalog={catalog} users={users}></Users>
                    : selectedDashboard === "encuestas" ? <Encuestas encuestasQuestions={encuestasQuestions}></Encuestas>
                      : dashboardType === "course" ?
                        <Course
                          keycloak={keycloak}
                          course={catalog[selectedDashboard]}
                          users={users}
                          selectedImplementation={selectedImplementation}
                          setSelectedImplementation={setSelectedImplementation}
                          selectedSection={selectedSection}
                          setSelectedSection={setSelectedSection}
                          encuestasQuestions={encuestasQuestions}
                        ></Course>
                        : dashboardType === "diplomado" ?
                          <Diplomado
                            keycloak={keycloak}
                            users={users}
                            diplomado={catalog[selectedDashboard]}
                            selectedImplementation={selectedImplementation}
                            setSelectedImplementation={setSelectedImplementation}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            encuestasQuestions={encuestasQuestions}
                          ></Diplomado>
                          : <></>
              }
            </Box>
          </>
        ) : (
          <Box width={"100%"} textAlign={"center"} mt={"200px"}>
            <Typography variant="p" textAlign={"center"} color="error">
              No tienes permiso para acceder a ningún dashboard.
            </Typography>
          </Box>
        )
      ) : (
        <Box width={"100%"} textAlign={"center"} mt={"200px"}>
          <CircularProgress sx={{ "color": "#d23831" }} />
        </Box>
      )}
    </Box>
  );
}

export default Admin;
