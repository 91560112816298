import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Box, Button, Grid, IconButton, Typography, FormGroup, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../../utils/fileUpload';
import { formatFileSize } from '../../../utils/formatters';
import { downloadExperimentalData } from '../../../requests/downloadExperimentalData';
import { makeSeguimiento } from '../../../requests/makeSeguimiento';
import { cancelSeguimiento } from '../../../requests/cancelSeguimiento';
import { checkSeguimiento } from '../../../requests/checkSeguimiento';


const Seguimiento = ({ keycloak, permissions, catalog, equivalencias }) => {

    const { trackEvent } = useMatomo();

    const [seguimientoStatus, setSeguimientoStatus] = useState(null);
    const [errorBox, setErrorBox] = useState(null);
    const [successBox, setSuccessBox] = useState(null);
    const [cancelBox, setCancelBox] = useState(null);
    const [studentStateFile, setStudentStateFile] = useState(null);
    const [oraReportFile, setOraReportFile] = useState(null);
    const [studentProfileFile, setStudentProfileFile] = useState(null);
    const [encuestas2022, setEncuestas2022] = useState(true);
    const [experimental, setExperimental] = useState(false);
    const [subirDrive, setSubirDrive] = useState(false);
    const [experimentalResults, setExperimentalResults] = useState(null);
    const [errors, setErrors] = useState({});
    const [formLoading, setFormLoading] = useState(false);

    const handleStudentStateFileChange = (event) => {
        setStudentStateFile(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, studentStateFile: '' }));
    };

    const resetStudentStateFileInput = () => {
        setStudentStateFile(null);
        setErrors(prevErrors => ({ ...prevErrors, studentStateFile: '' }));
    };

    const handleOraReportFileChange = (event) => {
        setOraReportFile(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, oraReportFile: '' }));
    }

    const resetOraReportFileInput = () => {
        setOraReportFile(null);
        setErrors(prevErrors => ({ ...prevErrors, oraReportFile: '' }));
    }

    const handleStudentProfileFileChange = (event) => {
        setStudentProfileFile(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, studentProfileFile: '' }));
    }

    const resetStudentProfileFileInput = () => {
        setStudentProfileFile(null);
        setErrors(prevErrors => ({ ...prevErrors, studentProfileFile: '' }));
    }

    const handleCancel = async () => {
        trackEvent({ category: 'Admin', action: `Canceló seguimiento${experimental ? " (experimental)" : ""}` });
        setFormLoading(true);
        const result = await cancelSeguimiento(keycloak.token);
        if (result.status) {
            setSeguimientoStatus(result.status);
            setCancelBox("Se ha cancelado el seguimiento.");
        }
        setFormLoading(false);
    }

    const handleSubmit = async () => {
        const extractFilenameParts = (filename, input) => {
            try {
                const parts = filename.split('_');
                if (parts[0] === "cmm") {
                    if (parts.length < 4) throw new Error('Formato de nombre inválido.');
            
                    const codigosSec = parts[1];
                    const year = parts[2];
                    const sem = parts[3].split('.')[0];
            
                    if (codigosSec.length !== 16 || !/^\d{4}$/.test(year) || !/^[1-2]$/.test(sem)) {
                        throw new Error('Formato de nombre inválido.');
                    }
            
                    if (!filename.includes(input)) {
                        throw new Error('Archivo inválido.');
                    }
            
                    return {
                        codigosSec,
                        year,
                        sem,
                        programa: codigosSec.slice(0, 3),
                        subprograma: codigosSec.slice(3, 8),
                        siglasCurso: equivalencias[codigosSec.slice(8, 11)] ? equivalencias[codigosSec.slice(8, 11)] : codigosSec.slice(8, 11),
                        lugarCurso: codigosSec.slice(11, 14),
                        seccion: codigosSec.slice(14, 16)
                    };
                } else if (parts[0] === "redfid") {
                    if (filename.includes("MODULO1REDFID")) {
                        // Special case for MODULO1REDFID
                        return {
                            year: "2022",
                            sem: "2",
                            programa: "FID",
                            subprograma: "ELEAR",
                            siglasCurso: "IPD",
                            lugarCurso: "SLF",
                            seccion: "01"
                        };
                    } else {
                        if (parts.length < 8) throw new Error('Formato de nombre inválido.');
            
                        const year = parts[6];
                        const sem = parts[7].split('.')[0];
                        const programa = "FID";
                        const subprograma = parts[3];
                        const siglasCurso = parts[2];
                        const lugarCurso = parts[4];
                        const seccion = parts[5];
            
                        // Validate fields
                        if (!/^\d{4}$/.test(year) || !/^[1-2]$/.test(sem) || !lugarCurso || !seccion) {
                            throw new Error('Formato de nombre inválido.');
                        }
            
                        return {
                            year,
                            sem,
                            programa,
                            subprograma,
                            siglasCurso,
                            lugarCurso,
                            seccion
                        };
                    }
                } else {
                    throw new Error('Formato de nombre inválido.');
                }
            } catch (error) {
                return { error: error.message };
            }
        };

        setErrorBox(null);
        setErrors({});
        setSuccessBox(null);
        setCancelBox(null);

        const studentStateFileParts = studentStateFile ? extractFilenameParts(studentStateFile.name, 'student_state') : { error: 'Por favor seleccione un archivo.' };
        const studentProfileFileParts = studentProfileFile ? extractFilenameParts(studentProfileFile.name, 'student_profile') : { error: 'Por favor seleccione un archivo.' };
        const oraReportFileParts = oraReportFile ? extractFilenameParts(oraReportFile.name, 'ORA_data') : { error: 'Por favor seleccione un archivo.' };

        if (studentStateFileParts.error || studentProfileFileParts.error || oraReportFileParts.error) {
            setErrors(prevErrors => ({
                studentStateFile: studentStateFileParts.error || '',
                studentProfileFile: studentProfileFileParts.error || '',
                oraReportFile: oraReportFileParts.error || ''
            }));
            return;
        }

        const { codigosSec: studentStateCodigosSec, year: studentStateYear, sem: studentStateSem } = studentStateFileParts;
        const { codigosSec: studentProfileCodigosSec, year: studentProfileYear, sem: studentProfileSem } = studentProfileFileParts;
        const { codigosSec: oraReportCodigosSec, year: oraReportYear, sem: oraReportSem } = oraReportFileParts;

        if (studentStateYear !== studentProfileYear || studentProfileYear !== oraReportYear) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'Los archivos no corresponden al mismo año.' }));
            return;
        }
        if (studentStateSem !== studentProfileSem || studentProfileSem !== oraReportSem) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'Los archivos no corresponden al mismo semestre.' }));
            return;
        }
        if (studentStateCodigosSec !== studentProfileCodigosSec || studentProfileCodigosSec !== oraReportCodigosSec) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'Los archivos no corresponden al mismo curso.' }));
            return;
        }
        if (studentStateFileParts.subprograma === "DIPLO") {
            if (!catalog[studentStateFileParts.lugarCurso]) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Este diplomado (${studentStateFileParts.lugarCurso}) no se encuentra en el catálogo.` }));
                return;
            } else if (!catalog[studentStateFileParts.lugarCurso].modules[studentStateFileParts.siglasCurso]) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Este módulo (${studentStateFileParts.siglasCurso}) del diplomado ${studentStateFileParts.lugarCurso} no se encuentra en el catálogo.` }));
                return;
            } else if (!catalog[studentStateFileParts.lugarCurso].modules[studentStateFileParts.siglasCurso].implementations.find(imp => imp.semester === studentStateFileParts.year + "-" + studentStateFileParts.sem)) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Esta implementación (${studentStateFileParts.year + "-" + studentStateFileParts.sem}) del módulo ${studentStateFileParts.siglasCurso} del diplomado ${studentStateFileParts.lugarCurso} no se encuentra en el catálogo.` }));
                return;
            } else if (!catalog[studentStateFileParts.lugarCurso].modules[studentStateFileParts.siglasCurso].implementations.find(imp => imp.semester === studentStateFileParts.year + "-" + studentStateFileParts.sem).sections.find(sec => sec.section === studentStateFileParts.seccion)) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Esta sección (${studentStateFileParts.seccion}) de la implementación ${studentStateFileParts.year}-${studentStateFileParts.sem} del módulo ${studentStateFileParts.siglasCurso} del diplomado ${studentStateFileParts.lugarCurso} no se encuentra en el catálogo.` }));
                return;
            }
        } else {
            if (!catalog[studentStateFileParts.siglasCurso]) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Este curso (${studentStateFileParts.siglasCurso}) no se encuentra en el catálogo.` }));
                return;
            } else if (!catalog[studentStateFileParts.siglasCurso].implementations.find(imp => (imp.semester === studentStateFileParts.year + "-" + studentStateFileParts.sem) && (imp.type[0].toUpperCase() + "LEAR" === studentStateFileParts.subprograma))) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Esta implementación (${studentStateFileParts.year}_${studentStateFileParts.sem}_${studentStateFileParts.subprograma[0]}) del curso ${studentStateFileParts.siglasCurso} no se encuentra en el catálogo.` }));
                return;
            } else if (!catalog[studentStateFileParts.siglasCurso].implementations.find(imp => (imp.semester === studentStateFileParts.year + "-" + studentStateFileParts.sem) && (imp.type[0].toUpperCase() + "LEAR" === studentStateFileParts.subprograma)).sections.find(sec => (sec.section === studentStateFileParts.seccion && sec.location === studentStateFileParts.lugarCurso))) {
                setErrors(prevErrors => ({ ...prevErrors, general: `Esta sección (${studentStateFileParts.lugarCurso + studentStateFileParts.seccion}) de la implementación ${studentStateFileParts.year}_${studentStateFileParts.sem}_${studentStateFileParts.subprograma[0]} del curso ${studentStateFileParts.siglasCurso} no se encuentra en el catálogo.` }));
                return;
            }
        }
        if (studentStateFileParts.subprograma !== "DIPLO" && !permissions.dashboards.includes("*") && !permissions.dashboards.includes(studentStateFileParts.siglasCurso)) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'No cuentas con permiso para ejecutar el Seguimiento para este curso.' }));
            return;
        }
        if (studentStateFileParts.subprograma === "DIPLO" && !permissions.dashboards.includes("*") && !permissions.dashboards.includes(studentStateFileParts.lugarCurso)) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'No cuentas con permiso para ejecutar el Seguimiento para este diplomado.' }));
            return;
        }
        setFormLoading(true);
        try {
            trackEvent({ category: 'Admin', action: `Ejecutó seguimiento${experimental ? " (experimental)" : ""}`, name: studentStateCodigosSec });
            const result = await makeSeguimiento(keycloak.token, studentProfileFile, oraReportFile, studentStateFile, encuestas2022, experimental, subirDrive);
            checkSeguimiento(keycloak.token).then((response) => {
                if (response.status) {
                    setSeguimientoStatus(response.status);
                    if (response.status.state === "SUCCESS" && response.status.error != null) {
                        setErrorBox(response.status.error);
                    } else if (response.status.state === "SUCCESS") {
                        setSuccessBox("No se han detectado errores. Los datos del curso querdaron actualizados.");
                    }
                }
            });
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, general: 'Error al enviar los archivos.' }));
        } finally {
            setFormLoading(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            checkSeguimiento(keycloak.token).then((response) => {
                if (response.status) {
                    setSeguimientoStatus(response.status);
                    if (response.status.state === "SUCCESS" && response.status.error != null) {
                        setErrorBox(response.status.error);
                    } else if (response.status.state === "SUCCESS") {
                        setSuccessBox("No se han detectado errores. Los datos del curso querdaron actualizados.");
                    }
                    if (response.status.state === "NONE" && response.status.experimental_results === true) {
                        setExperimentalResults(true);
                    } else {
                        setExperimentalResults(false);
                    }
                }
            });
        }, 1000);
    }, [seguimientoStatus]);

    return (
        <>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={experimentalResults ? 8 : 12}>
                    <Typography fontSize={"1.6em"} mb={"10px"} variant="title">Seguimiento</Typography>
                    <Typography fontSize={"1.0em"} variant="title">Actualización de base de datos de respuestas</Typography>
                </Grid>
                {experimentalResults ?
                    <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
                        <Box mb={"20px"}>
                            <Button startIcon={<DownloadIcon></DownloadIcon>} variant="red" onClick={async () => await downloadExperimentalData(keycloak.token)}>Resultados experimentales</Button>
                        </Box>
                    </Grid>
                    :
                    <></>
                }
            </Grid>
            <Typography variant='p'>
                Para actualizar las respuestas de los estudiantes en un curso, se debe descargar los archivos solicitados desde la sección correspondiente en
                Studio de Open edX. Tras validar los archivos, el sistema de Seguimiento detectará automáticamente el curso e incorporará los nuevos
                datos en la base de datos de Suma y Sigue.
            </Typography>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={4} mt={"20px"} display={"flex"} flexDirection={"column"} justifyContent={"start"}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="red"
                        tabIndex={-1}
                        onChange={handleStudentStateFileChange}
                        startIcon={<CloudUploadIcon />}
                        disabled={formLoading}
                    >
                        Subir respuestas
                        <FileUpload type="file" onChange={handleStudentStateFileChange} accept={".csv"} disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"} />
                    </Button>
                    <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                        {studentStateFile && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                    {studentStateFile ? `${studentStateFile.name} (${formatFileSize(studentStateFile.size)})` : "Por favor seleccione un archivo..."}
                                </Typography>
                                <IconButton disabled={formLoading || seguimientoStatus.state === "PROGRESS"} sx={{ marginLeft: "10px" }} size="small" onClick={resetStudentStateFileInput}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        )}
                    </Typography>
                    {errors.studentStateFile ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.studentStateFile}</Typography> : <></>}
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"} display={"flex"} flexDirection={"column"} justifyContent={"start"}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="red"
                        tabIndex={-1}
                        onChange={handleStudentProfileFileChange}
                        startIcon={<CloudUploadIcon />}
                        disabled={formLoading}
                    >
                        Subir perfil de estudiantes
                        <FileUpload type="file" onChange={handleStudentProfileFileChange} accept={".csv"} disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"} />
                    </Button>
                    <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                        {studentProfileFile && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                    {studentProfileFile ? `${studentProfileFile.name} (${formatFileSize(studentProfileFile.size)})` : "Por favor seleccione un archivo..."}
                                </Typography>
                                <IconButton disabled={formLoading || seguimientoStatus.state === "PROGRESS"} sx={{ marginLeft: "10px" }} size="small" onClick={resetStudentProfileFileInput}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        )}
                    </Typography>
                    {errors.studentProfileFile ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.studentProfileFile}</Typography> : <></>}
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"} display={"flex"} flexDirection={"column"} justifyContent={"start"}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="red"
                        tabIndex={-1}
                        onChange={handleOraReportFileChange}
                        startIcon={<CloudUploadIcon />}
                        disabled={formLoading}
                    >
                        Subir reporte ORA
                        <FileUpload type="file" onChange={handleOraReportFileChange} accept={".csv"} disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"} />
                    </Button>
                    <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                        {oraReportFile && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                    {oraReportFile ? `${oraReportFile.name} (${formatFileSize(oraReportFile.size)})` : "Por favor seleccione un archivo..."}
                                </Typography>
                                <IconButton disabled={formLoading || seguimientoStatus.state === "PROGRESS"} sx={{ marginLeft: "10px" }} size="small" onClick={resetOraReportFileInput}>
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        )}
                    </Typography>
                    {errors.oraReportFile ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.oraReportFile}</Typography> : <></>}
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"start"} mt={"30px"} ml={"20px"}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={encuestas2022}
                                    onChange={(e) => setEncuestas2022(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"}
                                />
                            } label="Encuestas Clase 3 — Marcar si está actualizando un curso del 2022 en adelante" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"start"} mt={"10px"} ml={"20px"}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={experimental}
                                    onChange={(e) => setExperimental(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"}
                                />
                            } label="Experimental — Marcar si se está probando el Seguimiento. Los datos resultantes se descargarán directamente, en vez de actualizar la base de datos. Al ejecutar un nuevo Seguimiento, estos datos se descartarán." />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"start"} mt={"10px"} ml={"20px"} mb={"20px"}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={subirDrive}
                                    onChange={(e) => setSubirDrive(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={formLoading || !seguimientoStatus || seguimientoStatus.state === "PROGRESS"}
                                />
                            } label="Subir a Drive — Marcar si se desea clonar el reporte resultante y subirlo a Google Drive." />
                    </FormGroup>
                </Grid>
                {(seguimientoStatus && seguimientoStatus.state) &&
                    <>
                        {seguimientoStatus.state === "PROGRESS" ?
                            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', m: "20px 40px", width: "100%" }}>
                                {seguimientoStatus.message ?
                                    <Box sx={{ mt: "20px", width: "100%" }} border={1} borderColor="#646464" borderRadius={1} p={2} textAlign={"center"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                                        <Typography sx={{ marginTop: "20px", textAlign: "center" }} variant="title-small">Seguimiento en ejecución</Typography>
                                        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                            <Typography variant="p-small">Curso:</Typography>
                                            <Typography fontWeight={"bold"} m={"0 0 0 10px"} variant="p-small">{seguimientoStatus.task_data[1]}</Typography>
                                            <Typography m={"0 20px"} variant="p-small">|</Typography>
                                            {seguimientoStatus.task_data[2] !== "" &&
                                                <>
                                                    <Typography variant="p-small">Módulo: </Typography>
                                                    <Typography fontWeight={"bold"} m={"0 0 0 10px"} variant="p-small">{seguimientoStatus.task_data[2]}</Typography>
                                                    <Typography m={"0 20px"} variant="p-small">|</Typography>
                                                </>
                                            }
                                            <Typography variant="p-small">Implementación: </Typography>
                                            <Typography fontWeight={"bold"} m={"0 0 0 10px"} variant="p-small">{seguimientoStatus.task_data[3]}</Typography>
                                            <Typography m={"0 20px"} variant="p-small">|</Typography>
                                            <Typography variant="p-small">Sección: </Typography>
                                            <Typography fontWeight={"bold"} m={"0 0 0 10px"} variant="p-small">{seguimientoStatus.task_data[4]}</Typography>
                                        </Box>
                                        <CircularProgress sx={{ color: "#d23831", margin: "30px 0" }} />
                                        <Typography sx={{ marginTop: "10px", marginBottom: "40px", fontSize: "0.85em", textAlign: "center", p: "0 20px" }} variant="p">{seguimientoStatus.message}</Typography>
                                        <Button mb={"20px"} variant='red' disabled={!permissions.dashboards.includes("*") && !permissions.dashboards.includes(seguimientoStatus.task_data[1])} onClick={handleCancel}>Cancelar</Button>
                                    </Box>
                                    :
                                    <></>
                                }
                            </Box>
                            :
                            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                <Button
                                    variant="red"
                                    onClick={handleSubmit}
                                    disabled={formLoading || seguimientoStatus.state === "PROGRESS"}
                                >
                                    Ejecutar seguimiento
                                </Button>
                            </Grid>
                        }
                        {errors.general ? <Typography sx={{ marginTop: "20px", fontSize: "0.85rem", textAlign: "center" }} width={"100%"} variant="p" color="error">{errors.general}</Typography> : <></>}
                    </>
                }
                {errorBox ?
                    <Box sx={{ m: "20px 40px", width: "100%" }} border={1} borderColor="#d23831" borderRadius={1} p={2}>
                        <Typography sx={{ marginTop: "20px", textAlign: "center" }} variant="title-small" color="error">Ha ocurrido un error</Typography>
                        <Typography sx={{ marginTop: "10px", fontSize: "0.85em", textAlign: "center", p: "0 20px" }} variant="p" color="error">{errorBox}</Typography>
                    </Box>
                    :
                    <></>
                }
                {successBox ?
                    <Box sx={{ m: "20px 40px", width: "100%" }} border={1} borderColor="green" borderRadius={1} p={2}>
                        <Typography sx={{ marginTop: "20px", textAlign: "center" }} variant="title-small" color="green">Proceso exitoso</Typography>
                        <Typography sx={{ marginTop: "10px", fontSize: "0.85em", textAlign: "center", p: "0 20px" }} variant="p" color="green">{successBox}</Typography>
                    </Box>
                    :
                    <></>
                }
                {cancelBox ?
                    <Box sx={{ m: "20px 40px", width: "100%" }} border={1} borderColor="#646464" borderRadius={1} p={2}>
                        <Typography sx={{ marginTop: "20px", textAlign: "center" }} variant="title-small">Seguimiento cancelado</Typography>
                        <Typography sx={{ marginTop: "10px", fontSize: "0.85em", textAlign: "center", p: "0 20px" }} variant="p">{cancelBox}</Typography>
                    </Box>
                    :
                    <></>
                }
            </Grid>
        </>
    );

}

export default Seguimiento;