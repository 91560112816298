import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, Typography, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UserModal from '../modals/UserModal';


const UsersTable = ({ keycloak, catalog, users }) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 25, page: 0 });
    const [selectedUser, setSelectedUser] = useState(null);

    const [detailModal, setDetailModal] = useState(false);

    const columns = [
        {
            field: 'id',
            disableColumnMenu: true,
            headerName: 'Usuario',
            sortable: false,
            width: 200,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'rut',
            disableColumnMenu: true,
            headerName: 'RUT',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'email',
            disableColumnMenu: true,
            headerName: 'Email',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'left',
        },
        {
            field: 'first_name',
            disableColumnMenu: true,
            headerName: 'Nombre',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'last_name',
            disableColumnMenu: true,
            headerName: 'Apellido',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'is_staff',
            disableColumnMenu: true,
            headerName: 'Staff',
            sortable: false,
            width: 60,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'enrollments',
            disableColumnMenu: true,
            headerName: 'Cursos',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => {
                return Object.keys(row.enrollments).length;
            }
        },
        {
            field: 'approved',
            disableColumnMenu: true,
            headerName: 'Aprobados',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'detail',
            disableColumnMenu: true,
            headerName: 'Detalles',
            sortable: false,
            width: 120,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={() => { setSelectedUser(params.row); setDetailModal(params.row) }}
                >
                    <MoreHorizIcon /> 
                </IconButton>
            ),
        },
    ];

    function makeRows() {
        let rows = [];
        for (let [key, value] of Object.entries(users)) {
            rows.push({
                id: key,
                email: value.email ? value.email : "—",
                rut: value.rut ? value.rut : "—",
                is_staff: value.is_staff ? <CheckCircleIcon sx={{ color: "green" }}></CheckCircleIcon> : "—",
                first_name: value.first_name ? value.first_name : "—",
                last_name: value.last_name ? value.last_name : "—",
                enrollments: value.enrollments ? value.enrollments : [],
                completed: value.completed ? value.completed : "—",
                approved: value.approved ? value.approved : "—",
                details: value.details ? value.details : "—",
            });
        }
        return rows;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Lista de todos los participantes de Suma y Sigue</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        Object.keys(users).length === 0 ?
            <Box width={"100%"} textAlign={"center"} mt={"100px"}>
                <Typography variant='p' textAlign={"center"}>No hay datos de usuarios disponibles.</Typography>
            </Box>
            :
            <div style={{ width: '100%' }}>
                <DataGrid
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    rows={makeRows()}
                    columns={columns}
                    pageSizeOptions={[25]}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: 'id',
                                    sort: 'asc',
                                },
                            ],
                        },
                        pagination: {
                            paginationModel
                        }
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
                {selectedUser && <UserModal
                    open={detailModal !== false}
                    handleClose={() => setDetailModal(false)}
                    title={selectedUser.id}
                    subtitle={"—"}
                    width="lg"
                    selectedUser={selectedUser}
                    catalog={catalog}
                />
                }
            </div>
    );
};

export default UsersTable;
