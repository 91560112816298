import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { formatCourseImplementationYears, formatCourseImplementationsUsersPerYear, formatCourseImplementationsSatisfaction, formatUsersByCourse } from '../../../utils/formatters';
import SummaryTable from './tables/SummaryTable';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const Summary = ({ courses, users }) => {

    const makeUsersByCoursePlot = () => {
        let data = formatUsersByCourse(users, courses);
        return (
            <BarChart
                xAxis={[{
                    scaleType: 'band',
                    data: data.labels,
                    tickLabelStyle: {
                        fontSize: 10,
                    },
                }]}
                series={[
                    {
                        data: data.approved,
                        label: "Aprobaron",
                        stack: 'stack1'
                    },
                    {
                        data: data.completed,
                        label: "Terminaron",
                        stack: 'stack1'
                    },
                    {
                        data: data.enrolled,
                        label: "No terminaron",
                        stack: 'stack1'
                    }
                    
                ]}
                colors={['green', '#f5a623', '#d23831']}
                slotProps={{
                    legend: {
                        padding: {
                            bottom: 10
                        },
                        labelStyle: {
                            fontSize: 10,
                        }
                    }
                }}
                margin={{ left: 30, top: 10, right: 20 }}
                {...{
                    height: 300,
                }}
            />
        );
    }

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Typography fontSize={"1.6em"} mb={"10px"} variant="title">Suma y Sigue</Typography>
                    <Typography fontSize={"1.0em"} variant="title">Plataforma de datos y seguimiento</Typography>
                </Grid>
                <Grid item xs={12} lg={4} display={"flex"} justifyContent={"end"}>
                    <Box mb={"20px"}>
                        <Button startIcon={<CalendarMonthIcon></CalendarMonthIcon>} variant="red" onClick={() => { }}>Catálogo de cursos</Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <Typography textAlign={"center"} fontSize={"1.2em"} mb={"10px"} variant="subtitle">Inscripciones por año</Typography>
                    <LineChart
                        xAxis={[{
                            data: formatCourseImplementationYears(courses),
                            valueFormatter: (value) => value.toString()
                        }]}
                        series={[
                            {
                                data: Object.values(formatCourseImplementationsUsersPerYear(users, courses)).map(x => x.enrolled),
                                label: "Inscripciones"
                            },
                            {
                                data: Object.values(formatCourseImplementationsUsersPerYear(users, courses)).map(x => x.completed),
                                label: "Cursos completados"
                            },
                            {
                                data: Object.values(formatCourseImplementationsUsersPerYear(users, courses)).map(x => x.approved),
                                label: "Cursos aprobados"
                            }
                        ]}
                        colors={['#4a90e2', '#f5a623', 'green']}
                        height={300}
                        grid={{ vertical: true, horizontal: true }}
                        slotProps={{
                            legend: {
                                padding: {
                                    bottom: 10
                                },
                                labelStyle: {
                                    fontSize: 10,
                                }
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography textAlign={"center"} fontSize={"1.2em"} mb={"10px"} variant="subtitle">Satisfacción general de los cursos</Typography>
                    <BarChart
                        dataset={formatCourseImplementationsSatisfaction(courses)}
                        xAxis={[{
                            scaleType: 'band',
                            dataKey: 'label',
                            tickLabelStyle: {
                                fontSize: 10,
                            },
                        }]}
                        series={[{ dataKey: 'value' }]}
                        colors={['#d23831']}
                        grid={{ vertical: true }}
                        slotProps={{
                            legend: {
                                hidden: true,
                            },
                        }}
                        margin={{ left: 20, top: 10, right: 20 }}
                        {...{
                            yAxis: [{
                                label: 'Respuestas',
                            }],
                            height: 300,
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography textAlign={"center"} fontSize={"1.2em"} mb={"10px"} variant="subtitle">Usuarios por curso</Typography>
                    {makeUsersByCoursePlot()}
                </Grid>
            </Grid>
        </>
    );

}

export default Summary;