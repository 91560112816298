import React from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatTimestamp } from '../../../../utils/formatters';


const UserModal = ({ open, handleClose, title, width, catalog, selectedUser }) => {

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={width}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3, pb: 1, "color": "#646464", "fontFamily": "DIN Bold", fontSize: "1.5em" }}>
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ "textAlign": "justify", "color": "#646464" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Perfil del usuario</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Usuario (edX):</b> {selectedUser.id}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>RUT:</b> {selectedUser.rut}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Staff:</b> {selectedUser.is_staff === true ? "Sí" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Nombre:</b> {selectedUser.first_name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Apellido paterno:</b> {selectedUser.last_name_1}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Apellido materno:</b> {selectedUser.last_name_2}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Correo:</b> {selectedUser.email ? <Link href={"mailto:" + selectedUser.email}>{selectedUser.email}</Link> : "—"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Teléfono:</b> {selectedUser.phone}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="p"><b>Medio de contacto:</b> {selectedUser.phone}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Establecimientos</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Cursos</Typography>
                        <Divider />
                    </Grid>
                </Grid>
                {Object.entries(selectedUser.enrollments).map(([key, value]) => (
                    <Grid container spacing={2} key={key} mt={2}>
                        <Grid item xs={12} key={key + "_title"}>
                            <Typography sx={{ textDecoration: "underline" }} fontWeight={"bold"} variant="p">
                                {key.slice(3, 5) === "__" ? catalog[key.slice(0, 3)].name : catalog[key.slice(0, 3)].name + " (" + catalog[key.slice(0, 3)].modules[key.slice(4, 7)].name + ")"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_implementation"}>
                            <Typography variant="p">
                                <b>Implementación:</b> {key.slice(3, 5) === "__" ? key.slice(5, 11) : key.slice(8, 14)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_section"}>
                            <Typography variant="p">
                                <b>Sección:</b> {key.slice(3, 5) === "__" ? key.slice(14, 19) : key.slice(18, 20)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_type"}>
                            <Typography variant="p">
                                <b>Modalidad:</b> {key.slice(3, 5) === "__" ? key[12] + "-learning" : "—"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_situation"}>
                            <Typography variant="p"><b>Situación:</b> {value.situation}</Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_approved"}>
                            <Typography variant="p"><b>Aprobado:</b> {value.approved ? <CheckCircleIcon /> : "—"}</Typography>
                        </Grid>
                        <Grid item xs={3} key={key + "_grade"}>
                            <Typography variant="p"><b>Nota final:</b> {value.grade}</Typography>
                        </Grid>
                        <Grid item xs={6} key={key + "_lastseen"}>
                            <Typography variant="p"><b>Visto por última vez:</b> {value.last_seen ? formatTimestamp(value.last_seen) : "—"}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </DialogContent>
        </Dialog>
    );
}

export default UserModal;