export const formatFileSize = (size) => {
    if (size === 0) {
        return "0";
    }
    if (size < 1024) return size + ' Bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${['Bytes', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatCourseImplementationYears = (courses) => {
    const extractYears = (course) => {
        const directYears = course.implementations?.map(impl => impl.semester.split('-')[0]) || [];
        const moduleYears = Object.values(course.modules || {}).flatMap(module =>
            module.implementations?.map(impl => impl.semester.split('-')[0]) || []
        );
        return [...directYears, ...moduleYears];
    };
    const yearsInRange = Array.from(new Set(
        Object.values(courses).flatMap(extractYears)
    ));
    const minYear = Math.min(...yearsInRange);
    const maxYear = Math.max(...yearsInRange);
    const allYears = Array.from({ length: maxYear - minYear + 1 }, (_, i) => (minYear + i).toString());
    const sortedCompleteYears = allYears.sort();
    return sortedCompleteYears;
};

export const formatCourseImplementationsUsersPerYear = (users, courses) => {
    const years = formatCourseImplementationYears(courses);
    const output = years.map(() => ({ enrolled: 0, completed: 0, approved: 0 }));
    for (let user of Object.values(users)) {
        for (let [key, value] of Object.entries(user.enrollments)) {
            if (key.slice(3, 5) === "__") {
                const year = key.slice(5, 9);
                const yearIndex = years.indexOf(year);
                if (yearIndex !== -1) {
                    if (value.approved) {
                        output[yearIndex].approved += 1;
                    }
                    if (value.completed) {
                        output[yearIndex].completed += 1;
                    }
                    output[yearIndex].enrolled += 1;
                }
            }
        }
    }
    return output;
}


export const formatUsersByCourse = (users, courses) => {
    const pureCourses = Object.values(courses).filter(c => !c.modules);
    const output = {
        "labels": pureCourses.map(c => c.id),
        "enrolled": Array(pureCourses.map(c => c.id).length).fill(0),
        "completed": Array(pureCourses.map(c => c.id).length).fill(0),
        "approved": Array(pureCourses.map(c => c.id).length).fill(0)
    }
    for (let user of Object.values(users)) {
        for (let [key, value] of Object.entries(user.enrollments)) {
            let courseKey = output.labels.indexOf(key.slice(0, 3));
            if (key.slice(3, 5) === "__") {
                if (value.approved) {
                    output.approved[courseKey] += 1;
                } else if (value.completed) {
                    output.completed[courseKey] += 1;
                } else {
                    output.enrolled[courseKey] += 1;
                }
            }
        }
    }
    return output;
}

export const formatCourseImplementationsSatisfaction = (courses) => {
    const extractSatisfaction = (course) => {
        const directSatisfaction = course.implementations?.map(impl => impl.satisfaction) || [];
        const moduleSatisfaction = Object.values(course.modules || {}).flatMap(module =>
            module.implementations?.map(impl => impl.satisfaction) || []
        );
        return [...directSatisfaction, ...moduleSatisfaction];
    };
    const satisfactionCounts = Array(10).fill(0);
    const allSatisfaction = Object.values(courses).flatMap(extractSatisfaction);
    const totalCount = allSatisfaction.length;
    allSatisfaction.forEach(satisfaction => {
        if (satisfaction >= 1 && satisfaction <= 10) {
            satisfactionCounts[satisfaction - 1] += 1;
        }
    });
    const satisfactionValues = satisfactionCounts.map((count, index) => ({
        label: (index + 1).toString(),
        value: totalCount > 0 ? parseFloat(((count / totalCount) * 100).toFixed(2)) : 0
    }));
    return satisfactionValues;
};




export const formatEncuestaAnswersSeleccionUnica = (answers, question, encuestasQuestions) => {
    let alternativeCounts = {};
    for (let user of Object.keys(answers)) {
        if (answers[user] === "unanswered") {
            continue;
        }
        let splitted = answers[user].split("_");
        for (let i = 0; i < splitted.length; i++) {
            if (!alternativeCounts[String.fromCharCode(65 + i)]) {
                alternativeCounts[String.fromCharCode(65 + i)] = 0;
            }
        }
        let trueIndex = splitted.indexOf("true");
        let alternativeLabel = String.fromCharCode(65 + trueIndex);

        if (alternativeCounts[alternativeLabel]) {
            alternativeCounts[alternativeLabel]++;
        } else {
            alternativeCounts[alternativeLabel] = 1;
        }
    }
    let output = [];
    for (let [label, value] of Object.entries(alternativeCounts)) {
        output.push({ value: value, label: encuestasQuestions.find(q => q.code === question.id.toLowerCase())["alternative_" + label.toLowerCase()].replace(/(.{50})\s+/g, '$1\n') });
    }
    return output;
}

export const formatEncuestaAnswersCinta = (answers, question, encuestasQuestions) => {
    let alternativeCounts = {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 0
    };
    for (let user of Object.keys(answers)) {
        if (answers[user] === "unanswered") {
            continue;
        }
        let alternativeLabel = answers[user]
        alternativeCounts[alternativeLabel]++;
    }
    let output = [];
    for (let [label, value] of Object.entries(alternativeCounts)) {
        output.push({ value: value, label: label.toString(), });
    }
    return output;
}

export const formatEncuestaAnswersAlternativesSize = (question, encuestasQuestions) => {
    let q = encuestasQuestions.find(q => q.code === question.id.toLowerCase())
    var maxLength = 0;
    for (let key of Object.keys(q)) {
        if (key.includes("alternative_")) {
            if (q[key] !== null) {
                maxLength = Math.max(maxLength, q[key].length);
            }
        }
    }
    return Math.min(5 * maxLength, 300);
}

export const formatEncuestaAnswerSeleccionMultiple = (answers, question, encuestasQuestions) => {
    let alternativeCounts = {};
    for (let user of Object.keys(answers)) {
        if (answers[user] === "unanswered") {
            continue;
        }
        let splitted = answers[user].split("_");
        for (let i = 0; i < splitted.length; i++) {
            if (!alternativeCounts[String.fromCharCode(65 + i)]) {
                alternativeCounts[String.fromCharCode(65 + i)] = 0;
            }
        }
        for (let i = 0; i < splitted.length; i++) {
            if (splitted[i] === "true") {
                let alternativeLabel = String.fromCharCode(65 + i);
                if (alternativeCounts[alternativeLabel]) {
                    alternativeCounts[alternativeLabel]++;
                } else {
                    alternativeCounts[alternativeLabel] = 1;
                }
            }
        }
    }
    let output = [];
    for (let [label, value] of Object.entries(alternativeCounts)) {
        output.push({ value: value, label: encuestasQuestions.find(q => q.code === question.id.toLowerCase())["alternative_" + label.toLowerCase()].replace(/(.{50})\s+/g, '$1\n') });
    }
    return output;
}

export const getChosenAlternatives = (answer) => {
    let chosenAlternatives = [];
    if (answer === "unanswered") {
        return [];
    }
    let splitted = answer.split("_");
    for (let i = 0; i < splitted.length; i++) {
        if (splitted[i] === "true") {
            chosenAlternatives.push(String.fromCharCode(65 + i));
        }
    }
    return chosenAlternatives;
}

export const formatTimestamp = (timestamp) => {
    const date = isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));
    const formatter = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    });
    return formatter.format(date);
}

export const formatCourseStructure = (structureData) => {
    let structure = [];
    let sections = []
    for (let question of structureData.preguntas) {
        if (!sections.includes(question.tallernombre.trim())) {
            sections.push(question.tallernombre.trim());
            let thisSection = {
                section: question.tallernombre.trim(),
                taller: question.tallernumero,
                presencial: (question.tallernumero !== -1 && !question.detaller),
                is_modulo_inicial: question.moduloinicial,
                is_modulo_final: question.modulofinal,
                subsections: []
            }
            let thisSubsection = {
                subsection: question.actividadnombre.trim(),
                taller: question.tallernumero,
                actividad: question.actividadnumero,
                control: question["Es de control"],
                units: []
            }
            let thisUnit = {
                unit: question.pagina.trim(),
                xblocks: []
            }
            let thisXblock = {
                block_id: question.codigo,
                block_type: question.tipo,
                is_activity: question["Es de actividad"],
                is_control: question["Es de control"],
                is_encuesta: question["Es de encuesta"],
                is_consentimiento: question.consentimiento,
                is_taller: question.detaller,
                is_modulo_inicial: question.moduloinicial,
                is_modulo_final: question.modulofinal,
                is_multiple_respuesta: question.multiplerespuesta,
                numero_control: question.numerocontrol,
                ora_code: question.oracode,
                is_postest: question.postest,
                is_pregunta_calificada: question.preguntacalificada,
                is_pretest: question.pretest,
                is_reglamento: question.reglamento,
                is_ora: question.tipoora,
            }
            thisUnit.xblocks.push(thisXblock);
            thisSubsection.units.push(thisUnit);
            thisSection.subsections.push(thisSubsection);
            structure.push(thisSection);
        } else {
            let thisSection = structure.find(s => s.section === question.tallernombre.trim());
            let subsections = thisSection.subsections.map(s => s.subsection);
            if (!subsections.includes(question.actividadnombre.trim())) {
                let thisSubsection = {
                    subsection: question.actividadnombre.trim(),
                    taller: question.tallernumero,
                    actividad: question.actividadnumero,
                    control: question["Es de control"],
                    units: []
                }
                let thisUnit = {
                    unit: question.pagina.trim(),
                    xblocks: []
                }
                let thisXblock = {
                    block_id: question.codigo,
                    block_type: question.tipo,
                    is_activity: question["Es de actividad"],
                    is_control: question["Es de control"],
                    is_encuesta: question["Es de encuesta"],
                    is_consentimiento: question.consentimiento,
                    is_taller: question.detaller,
                    is_multiple_respuesta: question.multiplerespuesta,
                    numero_control: question.numerocontrol,
                    ora_code: question.oracode,
                    is_postest: question.postest,
                    is_pregunta_calificada: question.preguntacalificada,
                    is_pretest: question.pretest,
                    is_reglamento: question.reglamento,
                    is_ora: question.tipoora,
                }
                thisUnit.xblocks.push(thisXblock);
                thisSubsection.units.push(thisUnit);
                thisSection.subsections.push(thisSubsection);
            } else {
                let thisSubsection = thisSection.subsections.find(s => s.subsection === question.actividadnombre.trim());
                let units = thisSubsection.units.map(u => u.unit);
                if (!units.includes(question.pagina.trim())) {
                    let thisUnit = {
                        unit: question.pagina.trim(),
                        xblocks: []
                    }
                    let thisXblock = {
                        block_id: question.codigo,
                        block_type: question.tipo,
                        is_activity: question["Es de actividad"],
                        is_control: question["Es de control"],
                        is_encuesta: question["Es de encuesta"],
                        is_consentimiento: question.consentimiento,
                        is_taller: question.detaller,
                        is_modulo_inicial: question.moduloinicial,
                        is_modulo_final: question.modulofinal,
                        is_multiple_respuesta: question.multiplerespuesta,
                        numero_control: question.numerocontrol,
                        ora_code: question.oracode,
                        is_postest: question.postest,
                        is_pregunta_calificada: question.preguntacalificada,
                        is_pretest: question.pretest,
                        is_reglamento: question.reglamento,
                        is_ora: question.tipoora,
                    }
                    thisUnit.xblocks.push(thisXblock);
                    thisSubsection.units.push(thisUnit);
                } else {
                    let thisUnit = thisSubsection.units.find(s => s.unit === question.pagina.trim());
                    let thisXblock = {
                        block_id: question.codigo,
                        block_type: question.tipo,
                        is_activity: question["Es de actividad"],
                        is_control: question["Es de control"],
                        is_encuesta: question["Es de encuesta"],
                        is_consentimiento: question.consentimiento,
                        is_taller: question.detaller,
                        is_modulo_inicial: question.moduloinicial,
                        is_modulo_final: question.modulofinal,
                        is_multiple_respuesta: question.multiplerespuesta,
                        numero_control: question.numerocontrol,
                        ora_code: question.oracode,
                        is_postest: question.postest,
                        is_pregunta_calificada: question.preguntacalificada,
                        is_pretest: question.pretest,
                        is_reglamento: question.reglamento,
                        is_ora: question.tipoora,
                    }
                    thisUnit.xblocks.push(thisXblock);
                }
            }
        }
    }
    return structure;
}