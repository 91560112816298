import React, { useState, useEffect } from 'react';
import { getLevelChip, getTopicChip } from '../../../utils/chips';
import { Typography, Box, Tab, Tabs } from '@mui/material';
import { getDiplomadoData } from '../../../requests/getDiplomadoData';
import DiplomadoReviewIndicator from './indicators/DiplomadoReviewIndicator';
import ImplementationForm from './forms/ImplementationForm';
import DiplomadoResumen from './diplomado/DiplomadoResumen';
import DiplomadoAvance from './diplomado/DiplomadoAvance';
import DiplomadoEncuestas from './diplomado/DiplomadoEncuestas';
import DiplomadoCalificaciones from './diplomado/DiplomadoCalificaciones';
import DiplomadoExplorador from './diplomado/DiplomadoExplorador';

function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Diplomado = ({ keycloak, diplomado, users, selectedImplementation, setSelectedImplementation, selectedSection, setSelectedSection, encuestasQuestions }) => {

    const [diplomadoData, setDiplomadoData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState("resumen");

    const handleTabs = (event, newValue) => {
        setTab(newValue);
    }

    const fetchData = async (index) => {
        setLoading(true);
        const { diplomadoData, error } = await getDiplomadoData(keycloak.token, diplomado.id, diplomado.modules[Object.keys(diplomado.modules)[0]].implementations[index]["semester"], diplomado.modules[Object.keys(diplomado.modules)[0]].implementations[index]["type"]);
        if (error) {
            console.log(error);
            return;
        }
        setDiplomadoData(diplomadoData);
        setLoading(false);
    }

    useEffect(() => {
        setDiplomadoData(null);
        setTab("resumen");
    }, [diplomado]);

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"} mb={"20px"} width={"100%"}>
                <Box mr={"20px"} width={"100%"}>
                    <Typography fontSize={"1.6em"} variant="title" mb={0}>{diplomado.name}</Typography>
                    <Box display={"flex"} justifyContent={"start"} mb={"20px"} mt={"10px"}>
                        <Box mr={"10px"}>
                            {getLevelChip(diplomado.level)}
                        </Box>
                    </Box>
                    <Typography variant='p-small'>{diplomado.description}</Typography>
                    <ImplementationForm
                        course={diplomado}
                        isDiplomado={true}
                        selectedImplementation={selectedImplementation}
                        setSelectedImplementation={setSelectedImplementation}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        fetchData={fetchData}
                    />
                </Box>
                <DiplomadoReviewIndicator diplomado={diplomado} />
            </Box>
            <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} sx={{
                "&& .Mui-selected": {
                    color: 'white',
                    backgroundColor: '#d23831',
                },
                '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                },
                marginTop: "10px",
                marginBottom: "20px"
            }}>
                <Tab label={
                    <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                        Resumen
                    </div>
                }
                    value={"resumen"} />
                <Tab label={
                    <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                        Avance
                    </div>
                }
                    value={"avance"} />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Encuestas e instrumentos
                        </div>
                    }
                    value={"encuestas"}
                />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Calificaciones
                        </div>
                    }
                    value={"calificaciones"}
                />
                <Tab
                    label={
                        <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
                            Explorador de respuestas
                        </div>
                    }
                    value={"explorador"}
                />
            </Tabs>
            <CustomTabPanel value={tab} index={"resumen"}>
                <DiplomadoResumen
                    keycloak={keycloak}
                    course={diplomado}
                    courseData={diplomadoData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"avance"}>
                <DiplomadoAvance
                    course={diplomado}
                    courseData={diplomadoData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"encuestas"}>
                <DiplomadoEncuestas
                    course={diplomado}
                    courseData={diplomadoData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                    encuestasQuestions={encuestasQuestions}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"calificaciones"}>
                <DiplomadoCalificaciones
                    course={diplomado}
                    courseData={diplomadoData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={"explorador"}>
                <DiplomadoExplorador
                    course={diplomado}
                    courseData={diplomadoData}
                    selectedImplementation={selectedImplementation}
                    selectedSection={selectedSection}
                    loading={loading}
                />
            </CustomTabPanel>
        </>
    );

}

export default Diplomado;