import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import { Typography, Box, Grid, TextField, MenuItem, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import regions from '../../assets/json/regions.json';
import establecimientos from '../../assets/json/establecimientos.json';
import { validateRut, validateEmail } from '../../utils/validators';
import { getExperienciaSys } from '../../requests/getExperienciaSys';


const TrayectoriasForm = ({ }) => {

    const { trackPageView, trackEvent } = useMatomo();

    const [question1, setQuestion1] = useState("");
    const [question2, setQuestion2] = useState("");
    const [question3, setQuestion3] = useState("");
    const [question4, setQuestion4] = useState("none");
    const [question5, setQuestion5] = useState("none");
    const [question6, setQuestion6] = useState("none");
    const [question7, setQuestion7] = useState("none");
    const [question7Other, setQuestion7Other] = useState("");
    const [question8, setQuestion8] = useState("");
    const [question9, setQuestion9] = useState("");
    const [question10, setQuestion10] = useState("none");
    const [question11, setQuestion11] = useState("none");
    const [question12, setQuestion12] = useState("none");
    const [question13, setQuestion13] = useState("none");
    const [question14, setQuestion14] = useState("none");
    const [question15, setQuestion15] = useState("none");
    const [question16, setQuestion16] = useState("none");
    const [question17A, setQuestion17A] = useState(false);
    const [question17B, setQuestion17B] = useState(false);
    const [question17C, setQuestion17C] = useState(false);
    const [question17D, setQuestion17D] = useState(false);
    const [question17E, setQuestion17E] = useState(false);
    const [question17F, setQuestion17F] = useState(false);
    const [question17G, setQuestion17G] = useState(false);
    const [question17Other, setQuestion17Other] = useState("");
    const [question18A, setQuestion18A] = useState(false);
    const [question18B, setQuestion18B] = useState(false);
    const [question18C, setQuestion18C] = useState(false);
    const [question18D, setQuestion18D] = useState(false);
    const [question18E, setQuestion18E] = useState(false);
    const [question19, setQuestion19] = useState("none");
    const [question19Other, setQuestion19Other] = useState("");
    const [errors, setErrors] = useState({});

    const [navPage, setNavPage] = useState(1);

    const calculateResult = async () => {
        var score = 0;
        var cicloPreferente;
        var ejePreferente;
        var experienciaSys;
        var ejeExperiencia;
        var haceClases78;
        if ((question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos") && question14 === "Primer ciclo") {
            cicloPreferente = "C1";
        } else {
            cicloPreferente = "C2";
        }
        ejePreferente = question18A ? "Datos y Azar" : question18B ? "Geometría" : question18C ? "Medición" : question18D ? "Números y operaciones" : question18E ? "Patrones y álgebra" : "Sin preferencia";
        experienciaSys = await getExperienciaSys(question2);
        ejeExperiencia = question17A ? "Datos y Azar" : question17B ? "Geometría" : question17C ? "Medición" : question17D ? "Números y operaciones" : question17E ? "Patrones y álgebra" : question17F ? "Probabilidades" ? question17G : "Otro" : "Sin experiencia";
        haceClases78 = question11 === "Sí" ? "Sí" : "No";
    }

    const handleNavigationAndValidation = (current, action) => {
        if (current === 1 && action === "next") {
            const newErrors = {}
            if (question1 === "") {
                newErrors.question1 = "Por favor indique su nombre.";
            } else if (question1.length < 3 || question1.length > 50) {
                newErrors.question1 = "Por favor indique un nombre entre 3 y 50 caracteres.";
            } else if (question1.split(" ").length < 2) {
                newErrors.question1 = "Por favor indique su nombre completo.";
            }
            if (question2 === "") {
                newErrors.question2 = "Por favor indique su RUT.";
            } else if (!validateRut(question2)) {
                newErrors.question2 = "Por favor ingrese un RUT válido (sin puntos y con guion).";
            }
            if (question3 === "") {
                newErrors.question3 = "Por favor indique su correo electrónico.";
            } else if (!validateEmail(question3)) {
                newErrors.question3 = "Por favor ingrese un correo electrónico válido.";
            }
            if (question4 === "none") {
                newErrors.question4 = "Por favor seleccione una región.";
            }
            if (question5 === "none") {
                newErrors.question5 = "Por favor seleccione una comuna.";
            }
            if (question6 === "none") {
                newErrors.question6 = "Por favor indique un establecimiento.";
            }
            if (question7 === "none") {
                newErrors.question7 = "Por favor indique su género.";
            }
            if (question7 === "Otro" && question7Other === "") {
                newErrors.question7Other = "Por favor indique su género.";
            } else if (question7 === "Otro" && (question7Other.length < 3 || question7Other.length > 50)) {
                newErrors.question7Other = "Por favor indique un género entre 3 y 50 caracteres.";
            }
            if (question8 === "") {
                newErrors.question8 = "Por favor indique su edad.";
            } else if (isNaN(parseInt(question8)) || parseInt(question8) < 18 || parseInt(question8) > 100) {
                newErrors.question8 = "La edad debe ser un número entre 18 y 100.";
            }
            if (question9 === "") {
                newErrors.question9 = "Por favor indique los años de experiencia como docente.";
            } else if (isNaN(parseInt(question9)) || parseInt(question9) < 0 || parseInt(question9) > 100) {
                newErrors.question9 = "Los años de experiencia deben ser un número entre 0 y 100.";
            }
            if (question10 === "none") {
                newErrors.question10 = "Por favor seleccione una opción.";
            }
            setErrors(newErrors);
            if (Object.keys(newErrors).length !== 0) {
                setNavPage(2);
                window.scrollTo(0, 0);
            }
        } else if (current === 2 && action === "next") {
            const newErrors = {}
            if (question10 === "Enseñanza media") {
                if (question11 === "none") {
                    newErrors.question11 = "Por favor seleccione una opción.";
                }
            }
            if ((question10 !== "Enseñanza media" && question10 !== "none") || (question10 === "Enseñanza media" && question11 !== "none")) {
                if (question12 === "none") {
                    newErrors.question12 = "Por favor seleccione una opción.";
                }
            }
            if (question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos") {
                if (question13 === "none") {
                    newErrors.question13 = "Por favor seleccione una opción.";
                }
            }
            if (question12 === "No tengo título en el área de educación" || question13 !== "none") {
                if (question14 === "none") {
                    newErrors.question14 = "Por favor seleccione una opción.";
                }
            }
            if (question12 === "Profesor(a) de Educación Media" || (question12 === "No tengo título en el área de educación" && question14 !== "none") || (((question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos")) && question13 !== "none" && question14 !== "none")) {
                if (question15 === "none") {
                    newErrors.question15 = "Por favor seleccione una opción.";
                }
            }
            if (question15 !== "none") {
                if (question16 === "none") {
                    newErrors.question16 = "Por favor seleccione una opción.";
                }
            }
            if (question16 === "Sí") {
                if (!question17A && !question17B && !question17C && !question17D && !question17E && !question17F && !question17G) {
                    newErrors.question17 = "Por favor seleccione al menos una opción.";
                }
                if (question17G && question17Other === "") {
                    newErrors.question17Other = "Por favor indique un foco temático.";
                } else if (question17G && (question17Other.length < 3 || question17Other.length > 50)) {
                    newErrors.question17Other = "Por favor indique un foco temático entre 3 y 50 caracteres.";
                }
            }
            if (question16 === "No" || (question17A || question17B || question17C || question17D || question17E || question17F || question17G)) {
                if (!question18A && !question18B && !question18C && !question18D && !question18E) {
                    newErrors.question18 = "Por favor seleccione al menos una opción.";
                }
            }
            if (question18A || question18B || question18C || question18D || question18E) {
                if (question19 !== "none") {
                    if (question19 === "Otra razón") {
                        if (question19Other === "") {
                            newErrors.question19Other = "Por favor indique una razón.";
                        } else if (question19Other.length < 3 || question19Other.length > 50) {
                            newErrors.question19Other = "Por favor indique una razón entre 3 y 500 caracteres.";
                        }
                    }
                }
            }
            setErrors(newErrors);
            if (Object.keys(newErrors).length === 0) {
                let answers = {
                    "1": question1,
                    "2": question2,
                    "3": question3,
                    "4": question4,
                    "5": question5,
                    "6": question6,
                    "7": question7,
                    "7Other": question7Other,
                    "8": question8,
                    "9": question9,
                    "10": question10,
                    "11": question11,
                    "12": question12,
                    "13": question13,
                    "14": question14,
                    "15": question15,
                    "16": question16,
                    "17": {
                        "A": question17A,
                        "B": question17B,
                        "C": question17C,
                        "D": question17D,
                        "E": question17E,
                        "F": question17F,
                        "G": question17G
                    },
                    "17Other": question17Other,
                    "18": {
                        "A": question18A,
                        "B": question18B,
                        "C": question18C,
                        "D": question18D,
                        "E": question18E
                    },
                    "19": question19,
                    "19Other": question19Other,
                }
                console.log(answers);
                setNavPage(3);
                trackEvent({
                    category: 'Trayectorias',
                    action: 'Completó cuestionario',
                    name: "Trayectoria resultante: "
                });
                window.scrollTo(0, 0);
            }
        } else if (current === 2 && action === "back") {
            setNavPage(1);
            window.scrollTo(0, 0);
        } else if (current === 3 && action === "back") {
            setNavPage(2);
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        document.title = "Suma y Sigue | Cuestionario de trayectorias formativas";
        trackPageView();
    }, []);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header current={"trayectorias"} />
            <Grid
                p={"0 20px"}
                component="div"
                sx={{
                    flexGrow: 1,
                    m: '30px auto',
                    maxWidth: '940px',
                    width: '100%',
                }}
            >
                <Typography variant='subtitle' fontWeight={"bold"} m={"20px 0"} textAlign={"center"}>
                    CUESTIONARIO DE TRAYECTORIAS FORMATIVAS
                </Typography>
                <Typography variant='p' textAlign={"center"} m={"20px 0 40px 0"}>
                    Responda el siguiente cuestionario para conocer la trayectoria formativa que más se ajusta a sus necesidades.
                </Typography>
                {navPage === 1 && <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>1</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su nombre:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question1}
                            onChange={(e) => {
                                if (e.target.value.length > 50) return;
                                setQuestion1(e.target.value);
                                setErrors({ ...errors, question1: "" });
                            }}
                            fullWidth
                            error={!!errors.question1}
                            helperText={errors.question1}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>2</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su RUT:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question2}
                            onChange={(e) => {
                                if (e.target.value.length > 10) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9Kk-]/g, "");
                                setQuestion2(filteredValue);
                                setErrors({ ...errors, question2: "" });
                            }}
                            fullWidth
                            error={!!errors.question2}
                            helperText={errors.question2}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>3</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Indique su correo electrónico:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question3}
                            onChange={(e) => {
                                setQuestion3(e.target.value);
                                setErrors({ ...errors, question3: "" });
                            }}
                            fullWidth
                            error={!!errors.question3}
                            helperText={errors.question3}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>4</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Región:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question4}
                            onChange={(e) => {
                                setQuestion6("none");
                                setQuestion5("none");
                                setQuestion4(e.target.value);
                                setErrors({ ...errors, question4: "", question5: "" });
                            }}
                            fullWidth
                            error={!!errors.question4}
                            helperText={errors.question4}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            {regions.regions.map((region) => (
                                <MenuItem key={region.name} value={region.name}>
                                    {region.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>5</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Comuna:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question5}
                            onChange={(e) => {
                                setQuestion6("none");
                                setQuestion5(e.target.value);
                                setErrors({ ...errors, question5: "" });
                            }}
                            fullWidth
                            error={!!errors.question5}
                            helperText={errors.question5}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una {question4 === "none" ? "región" : "opción"}...</MenuItem>
                            {question4 !== "none" && regions.regions.find(region => region.name === question4).comunas.map((comuna) => (
                                <MenuItem key={comuna} value={comuna}>
                                    {comuna}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>6</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            Nombre del establecimiento en el que trabaja:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question6}
                            select
                            onChange={(e) => {
                                if (e.target.value.length > 100) return;
                                setQuestion6(e.target.value);
                                setErrors({ ...errors, question6: "" });
                            }}
                            fullWidth
                            error={!!errors.question6}
                            helperText={errors.question6}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una {question5 === "none" ? "comuna" : "opción"}...</MenuItem>
                            {question5 !== "none" && establecimientos
                                .filter(e => e.NOM_COM_RBD === question5.toUpperCase())
                                .map((e) => (
                                    <MenuItem key={e.RBD} value={e.RBD}>
                                        {e.NOM_RBD}
                                    </MenuItem>
                                )).sort((a, b) => a.props.children.localeCompare(b.props.children))
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>7</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuál es su género?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            select
                            value={question7}
                            onChange={(e) => {
                                setQuestion7(e.target.value);
                                setErrors({ ...errors, question7: "" });
                            }}
                            fullWidth
                            error={!!errors.question7}
                            helperText={errors.question7}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="Masculino">Masculino</MenuItem>
                            <MenuItem value="Femenino">Femenino</MenuItem>
                            <MenuItem value="Otro">Otro</MenuItem>
                        </TextField>
                    </Grid>
                    {question7 === "Otro" &&
                        <>
                            <Grid item xs={2} md={1}>
                            </Grid>
                            <Grid item xs={10} md={4}>
                                <Typography ml={"10px"} variant='p'>
                                    Indique su género:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    variant="outlined"
                                    value={question7Other}
                                    onChange={(e) => {
                                        setQuestion7Other(e.target.value);
                                        setErrors({ ...errors, question7Other: "" });
                                    }}
                                    fullWidth
                                    error={!!errors.question7Other}
                                    helperText={errors.question7Other}
                                >
                                </TextField>
                            </Grid>
                        </>
                    }
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>8</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuál es su edad?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question8}
                            onChange={(e) => {
                                if (e.target.value.length > 3) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, "");
                                setQuestion8(filteredValue);
                                setErrors({ ...errors, question8: "" });
                            }}
                            fullWidth
                            error={!!errors.question8}
                            helperText={errors.question8}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>9</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿Cuántos años de experiencia tiene como docente?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question9}
                            onChange={(e) => {
                                if (e.target.value.length > 3) return;
                                const value = e.target.value;
                                const filteredValue = value.replace(/[^0-9]/g, "");
                                setQuestion9(filteredValue);
                                setErrors({ ...errors, question9: "" });
                            }}
                            fullWidth
                            error={!!errors.question9}
                            helperText={errors.question9}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>10</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Typography ml={"10px"} variant='p'>
                            ¿En qué nivel hace clases de matemática actualmente?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            variant="outlined"
                            value={question10}
                            onChange={(e) => {
                                setQuestion10(e.target.value);
                                setErrors({ ...errors, question10: "" });
                            }}
                            fullWidth
                            select
                            error={!!errors.question10}
                            helperText={errors.question10}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="Primer ciclo de enseñanza básica">Primer ciclo de enseñanza básica</MenuItem>
                            <MenuItem value="Segundo ciclo de enseñanza básica">Segundo ciclo de enseñanza básica</MenuItem>
                            <MenuItem value="Enseñanza media">Enseñanza media</MenuItem>
                            <MenuItem value="No hago clases">No hago clases</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"} mt={"20px"}>
                        <Button variant="red" onClick={() => handleNavigationAndValidation(1, "next")}>Siguiente</Button>
                    </Grid>
                </Grid>
                }
                {navPage === 2 &&
                    <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        {question10 === "Enseñanza media" &&
                            <>
                                <Grid item xs={2} md={1}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>11</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography ml={"10px"} variant='p'>
                                        ¿Haces clases en 7° u 8° básico?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        value={question11}
                                        onChange={(e) => {
                                            setQuestion11(e.target.value);
                                            setErrors({ ...errors, question11: "" });
                                        }}
                                        fullWidth
                                        select
                                        error={!!errors.question11}
                                        helperText={errors.question11}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="Sí">Sí</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </TextField>
                                </Grid>
                            </>
                        }
                        {((question10 !== "Enseñanza media" && question10 !== "none") || (question10 === "Enseñanza media" && question11 !== "none")) &&
                            <>
                                <Grid item xs={2} md={1}>
                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>12</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography ml={"10px"} variant='p'>
                                        Indique el nombre que más se acerque a su título en el área de educación:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        value={question12}
                                        onChange={(e) => {
                                            setQuestion12(e.target.value);
                                            setErrors({ ...errors, question12: "" });
                                        }}
                                        fullWidth
                                        select
                                        error={!!errors.question12}
                                        helperText={errors.question12}
                                    >
                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                        <MenuItem value="Profesor(a) de Educación General Básica">Profesor(a) de Educación General Básica</MenuItem>
                                        <MenuItem value="Educador(a) Diferencial">Educador(a) Diferencial</MenuItem>
                                        <MenuItem value="Educador(a) de Párvulos">Educador(a) de Párvulos</MenuItem>
                                        <MenuItem value="Profesor(a) de Educación Media">Profesor(a) de Educación Media</MenuItem>
                                        <MenuItem value="No tengo título en el área de educación">No tengo título en el área de educación</MenuItem>
                                    </TextField>
                                </Grid>
                                {(question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos") &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>13</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿Usted posee mención en matemática, ya sea en su pregrado u obtenida a través de postítulo?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question13}
                                                onChange={(e) => {
                                                    setQuestion13(e.target.value);
                                                    setErrors({ ...errors, question13: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question13}
                                                helperText={errors.question13}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Sí">Sí, poseo una mención</MenuItem>
                                                <MenuItem value="No">No, no poseo ninguna mención</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </>
                                }
                                {(((question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos") && question13 !== "none") || question12 === "No tengo título en el área de educación") &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>14</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿En qué ciclo le gustaría especializarse?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question14}
                                                onChange={(e) => {
                                                    setQuestion14(e.target.value);
                                                    setErrors({ ...errors, question14: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question14}
                                                helperText={errors.question14}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Primer ciclo">Primer ciclo</MenuItem>
                                                <MenuItem value="Segundo ciclo">Segundo ciclo</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </>
                                }
                                {(question12 === "Profesor(a) de Educación Media" || (question12 === "No tengo título en el área de educación" && question14 !== "none") || (((question12 === "Profesor(a) de Educación General Básica" || question12 === "Educador(a) Diferencial" || question12 === "Educador(a) de Párvulos")) && question13 !== "none" && question14 !== "none")) &&
                                    <>
                                        <Grid item xs={2} md={1}>
                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>15</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} md={4}>
                                            <Typography ml={"10px"} variant='p'>
                                                ¿Ha hecho cursos de Suma y Sigue previamente?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <TextField
                                                variant="outlined"
                                                value={question15}
                                                onChange={(e) => {
                                                    setQuestion15(e.target.value);
                                                    setErrors({ ...errors, question15: "" });
                                                }}
                                                fullWidth
                                                select
                                                error={!!errors.question15}
                                                helperText={errors.question15}
                                            >
                                                <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                <MenuItem value="Sí">Sí</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </TextField>
                                        </Grid>
                                        {question15 !== "none" &&
                                            <>
                                                <Grid item xs={2} md={1}>
                                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>16</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10} md={4}>
                                                    <Typography ml={"10px"} variant='p'>
                                                        ¿Ha realizado otros cursos de Desarrollo Profesional Docente en el área de matemática?
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={question16}
                                                        onChange={(e) => {
                                                            setQuestion16(e.target.value);
                                                            setErrors({ ...errors, question16: "" });
                                                        }}
                                                        fullWidth
                                                        select
                                                        error={!!errors.question16}
                                                        helperText={errors.question16}
                                                    >
                                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                        <MenuItem value="Sí">Sí</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                {question16 === "Sí" &&
                                                    <>
                                                        <Grid item xs={2} md={1}>
                                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>17</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} md={4}>
                                                            <Typography ml={"10px"} variant='p'>
                                                                ¿Cuál o cuáles fueron los focos temáticos de estos cursos? Marque los que correspondan:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={7}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17A}
                                                                            onChange={(e) => {
                                                                                setQuestion17A(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Datos y azar" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17B}
                                                                            onChange={(e) => {
                                                                                setQuestion17B(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Geometría" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17C}
                                                                            onChange={(e) => {
                                                                                setQuestion17C(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Medición" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17D}
                                                                            onChange={(e) => {
                                                                                setQuestion17D(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Números y operaciones" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17E}
                                                                            onChange={(e) => {
                                                                                setQuestion17E(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Patrones y álgebra" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17F}
                                                                            onChange={(e) => {
                                                                                setQuestion17F(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Probabilidades" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question17G}
                                                                            onChange={(e) => {
                                                                                setQuestion17G(e.target.checked);
                                                                                setErrors({ ...errors, question17: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Otro" />
                                                            </FormGroup>
                                                            {errors.question17 && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.question17}</span>}
                                                        </Grid>
                                                        {question17G &&
                                                            <>
                                                                <Grid item xs={2} md={1}>
                                                                </Grid>
                                                                <Grid item xs={10} md={4}>
                                                                    <Typography ml={"10px"} variant='p'>
                                                                        Indique el foco temático:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={7}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={question17Other}
                                                                        onChange={(e) => {
                                                                            setQuestion17Other(e.target.value);
                                                                            setErrors({ ...errors, question17Other: "" });
                                                                        }}
                                                                        fullWidth
                                                                        error={!!errors.question17Other}
                                                                        helperText={errors.question17Other}
                                                                    >
                                                                    </TextField>
                                                                </Grid>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {(question16 === "No" || question17A || question17B || question17C || question17D || question17E || question17F || question17G) &&
                                                    <>
                                                        <Grid item xs={2} md={1}>
                                                            <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>18</Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} md={4}>
                                                            <Typography ml={"10px"} variant='p'>
                                                                ¿En qué ejes temáticos le gustaría especializarse?
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={7}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question18A}
                                                                            onChange={(e) => {
                                                                                setQuestion18A(e.target.checked);
                                                                                setErrors({ ...errors, question18: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Datos y azar" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question18B}
                                                                            onChange={(e) => {
                                                                                setQuestion18B(e.target.checked);
                                                                                setErrors({ ...errors, question18: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Geometría" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question18C}
                                                                            onChange={(e) => {
                                                                                setQuestion18C(e.target.checked);
                                                                                setErrors({ ...errors, question18: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Medición" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question18D}
                                                                            onChange={(e) => {
                                                                                setQuestion18D(e.target.checked);
                                                                                setErrors({ ...errors, question18: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Números y operaciones" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={question18E}
                                                                            onChange={(e) => {
                                                                                setQuestion18E(e.target.checked);
                                                                                setErrors({ ...errors, question18: "" });
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } sx={{ "color": '#646464' }} label="Patrones y álgebra" />
                                                            </FormGroup>
                                                            {errors.question18 && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.question18}</span>}
                                                        </Grid>
                                                        {((question16 === "No" && (question18A || question18B || question18C || question18D || question18E)) || (question16 === "Sí" && (question17A || question17B || question17C || question17D || question17E || question17F || question17G) && (question18A || question18B || question18C || question18D || question18E))) &&
                                                            <>
                                                                <Grid item xs={2} md={1}>
                                                                    <Box sx={{ width: "50px", height: "50px", backgroundColor: "#d23831", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <Typography variant='p' color={"white"} fontSize={"1.2em"} fontWeight={"bold"} textAlign={"center"}>19</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={10} md={4}>
                                                                    <Typography ml={"10px"} variant='p'>
                                                                        ¿Por qué desea cursar una trayectoria del programa Suma y Sigue?
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={7}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={question19}
                                                                        onChange={(e) => {
                                                                            setQuestion19(e.target.value);
                                                                            setErrors({ ...errors, question19: "" });
                                                                        }}
                                                                        fullWidth
                                                                        select
                                                                        error={!!errors.question19}
                                                                        helperText={errors.question19}
                                                                    >
                                                                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                                                                        <MenuItem value="Para mi desarrollo profesional">Para mi desarrollo profesional</MenuItem>
                                                                        <MenuItem value="Para enseñar matemática en un ciclo distinto">Para enseñar matemática en un ciclo distinto</MenuItem>
                                                                        <MenuItem value="Porque me recomendaron el programa">Porque me recomendaron el programa</MenuItem>
                                                                        <MenuItem value="Otra razón">Otra razón</MenuItem>
                                                                    </TextField>
                                                                </Grid>
                                                                {question19 === "Otra razón" &&
                                                                    <>
                                                                        <Grid item xs={2} md={1}>
                                                                        </Grid>
                                                                        <Grid item xs={10} md={4}>
                                                                            <Typography ml={"10px"} variant='p'>
                                                                                Indique su razón:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={7}>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                value={question19Other}
                                                                                onChange={(e) => {
                                                                                    setQuestion19Other(e.target.value);
                                                                                    setErrors({ ...errors, question19Other: "" });
                                                                                }}
                                                                                fullWidth
                                                                                error={!!errors.question19Other}
                                                                                helperText={errors.question19Other}
                                                                            >
                                                                            </TextField>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"start"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(2, "back")}>Anterior</Button>
                        </Grid>
                        <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"end"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(2, "next")}>Siguiente</Button>
                        </Grid>
                    </Grid>
                }
                {navPage === 3 &&
                    <Grid container spacing={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"start"} mt={"20px"}>
                            <Button variant="red" onClick={() => handleNavigationAndValidation(3, "back")}>Anterior</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Footer />
        </Grid >
    );

}

export default TrayectoriasForm;