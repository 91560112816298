import React from 'react';
import { Typography, Grid } from '@mui/material';
import UsersTable from './tables/UsersTable';


const Users = ({ keycloak, catalog, users }) => {

    return (
        <>
            <Typography fontSize={"1.6em"} mb={"10px"} variant="title">Usuarios</Typography>
            <Typography fontSize={"1.0em"} variant="title">Base de datos de inscritos en al menos un curso</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UsersTable keycloak={keycloak} catalog={catalog} users={users} />
                </Grid>
            </Grid>
        </>
    );
}

export default Users;